import React from 'react'
import { Trans } from '@lingui/react'
import { Popover } from '@connections/components'
import { formatPrice } from '../../../util/prices'

const FlightEconomicsPriceDetails = ({ charge, children }) => {
    const adultCharge = charge.adult
    const childCharge = charge.child
    const infantCharge = charge.infant

    return (
        <Popover
            content={(
                <div className="bg-gray-900 text-white rounded-lg mt-2 p-3 md:w-fit">
                    <div className="flex justify-between gap-x-4 md:block">
                        <div className="grid grid-cols-1 md:grid-cols-5 gap-x-4 gap-y-1 md:gap-y-0 text-sm font-medium mb-2">
                            <p>
                                <Trans id="Details" />
                            </p>
                            <p>
                                <Trans id="Ticket price" />
                            </p>
                            <p>
                                <Trans id="Taxes" />
                            </p>
                            <p>
                                <Trans id="Amount" />
                            </p>
                            <p>
                                <Trans id="Total" />
                            </p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-5 gap-x-4 gap-y-1 md:gap-y-0 text-sm mb-0.5">
                            <p>
                                <Trans id="1 adult" />
                            </p>
                            <p>{formatPrice(adultCharge.sum)}</p>
                            <p>{formatPrice(adultCharge.tax.total)}</p>
                            <p>{adultCharge.numberOfPersons}</p>
                            <p>{formatPrice(adultCharge.total)}</p>
                        </div>
                        {childCharge.numberOfPersons > 0 && (
                            <div className="grid grid-cols-1 md:grid-cols-5 gap-x-4 gap-y-1 md:gap-y-0 text-sm mb-0.5">
                                <p>
                                    <Trans id="1 child" />
                                </p>
                                <p>{formatPrice(childCharge.sum)}</p>
                                <p>{formatPrice(childCharge.tax.total)}</p>
                                <p>{childCharge.numberOfPersons}</p>
                                <p>{formatPrice(childCharge.total)}</p>
                            </div>
                        )}
                        {infantCharge.numberOfPersons > 0 && (
                            <div className="grid grid-cols-1 md:grid-cols-5 gap-x-4 gap-y-1 md:gap-y-0 text-sm mb-0.5">
                                <p>
                                    <Trans id="1 infant" />
                                </p>
                                <p>{formatPrice(infantCharge.sum)}</p>
                                <p>{formatPrice(infantCharge.tax.total)}</p>
                                <p>{infantCharge.numberOfPersons}</p>
                                <p>{formatPrice(infantCharge.total)}</p>
                            </div>
                        )}
                    </div>
                    <div className="w-full flex justify-between md:grid grid-cols-5 gap-x-4 font-bold mt-4 md:mt-2">
                        <p className="col-span-4">
                            <Trans id="Total" />
                        </p>
                        <p>{formatPrice(charge.total)}</p>
                    </div>
                </div>
            )}
        >
            {children}
        </Popover>
    )
}

export default FlightEconomicsPriceDetails
