import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { variant as createVariant } from '@connections/utils'

export const VARIANT_INLINE = 'inline'
export const VARIANT_DEFAULT = 'default'

const Select = forwardRef(
    (
        {
            value,
            className,
            isLoading,
            hasError = false,
            variant = VARIANT_DEFAULT,
            ...props
        },
        ref
    ) => (
        <div className={twMerge('relative', className)}>
            {isLoading && <div className="absolute skeleton w-full h-full" />}
            <select
                ref={ref}
                value={value}
                className={twMerge(
                    'disabled:bg-gray-50 disabled:cursor-not-allowed disabled:hover:border-gray-100',
                    value ? 'text-black' : 'text-gray-400',
                    hasError
                        ? 'border-primary'
                        : 'border-gray-100 hover:border-gray-300',
                    createVariant(variant, {
                        [VARIANT_INLINE]:
                            'border-none text-sm font-bold p-0 pr-8 -mr-2.5',
                        [VARIANT_DEFAULT]:
                            'border py-3.5 pl-4 pr-8 w-full leading-[22px] rounded text-sm',
                    }),
                    className
                )}
                {...props}
            />
        </div>
    )
)

Select.VARIANT_INLINE = VARIANT_INLINE
Select.VARIANT_DEFAULT = VARIANT_DEFAULT

export default Select
