import { twMerge } from 'tailwind-merge'
import useKeyPress from './hooks/useKeyPress'
import useBlockBodyScrolling from './hooks/useBlockBodyScrolling'
import Portal from './portal'
import ModalBackground from './modalBackground'

export default function Modal({ onClose, children, className, ...props }) {
    useBlockBodyScrolling()
    useKeyPress(({ keyCode }) => {
        if (keyCode === 27) {
            onClose()
        }
    })

    return (
        <Portal querySelector="#modals">
            <ModalBackground onClick={onClose} />
            <div
                tabIndex="-1"
                role="dialog"
                aria-modal="true"
                animate={{ y: 0 }}
                exit={{ y: '100%' }}
                initial={{ y: '100%' }}
                className="z-modal fixed inset-0 overflow-y-auto pointer-events-none"
                {...props}
            >
                <div
                    className={twMerge(
                        'relative bg-white lg:rounded-xl mx-auto my-0 lg:my-16 pointer-events-auto',
                        className
                    )}
                >
                    {children}
                </div>
            </div>
        </Portal>
    )
}
